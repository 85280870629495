import React, { Component } from "react"
import $ from "jquery"

export default class HeaderScripts extends Component {
  state = {
    isTop: true,
  }
  componentDidMount() {
    var header = $(".navbar")
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100
      if (isTop !== this.state.isTop) {
        header.addClass("scrolledNav")
      } else {
        header.removeClass("scrolledNav")
      }
    })
  }
  constructor() {
    super()
  }
  render() {
    return (
     <>
	</>
    )
  }
}