import React, { useState } from "react"
import { Link } from "gatsby"
import { logoImages } from "../constants/images.js"
import { useSiteMetadata } from "../hooks/meta"

const CustomNavbar = ({ the_location }) => {
  const [isOn, setIsOn] = useState(false)
  const { menuLinks } = useSiteMetadata()
  const nav_prefix = the_location
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <Link className="navbar-brand straw" to="/">
          <img src={logoImages.strawWo} className="logo" />
        </Link>
        <Link className="navbar-brand wood" to="/">
          <img src={logoImages.woodWo} className="logo" />
        </Link>
        <button
          className={`${
            isOn ? "is-active" : ""
          } hamburger hamburger--collapse navbar-toggler`}
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setIsOn(!isOn)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <div
          className={`collapse navbar-collapse ${isOn ? "show" : ""} `}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            {menuLinks.map(link => (
              <li className="menu-item">
                <Link
                  activeClassName="active"
                  className="nav-link"
                  key={link.title}
                  to={`/${nav_prefix}${link.route}`}
                >
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
          <Link className="btn straw" to="/wood">
            Wood Pellets
          </Link>
          <Link className="btn wood" to="/straw">
            Straw Pellets
          </Link>
        </div>
      </nav>
    </>
  )
}

export default CustomNavbar
