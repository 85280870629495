import React from "react"
import { Link } from "gatsby"
import { logoImages } from "../constants/images.js"
import { useSiteMetadata } from "../hooks/meta"

const Footer = ({the_class, the_location}) => {
	const { email, menuLinks } = useSiteMetadata()
	const nav_prefix = the_location
  return (
    <>
      <div className={`fw ${the_class}`}>
        <div className="container-fluid">
          <div className="row">
            <a
              href={`mailto: ${email}`}
              className="col-sm-12 col-md-6 dark-blue-bg text-center footer-cta email"
            >
              {email}
            </a>
            <Link
              to="/wood"
              className="col-sm-12 col-md-6 text-center footer-cta switch-sites straw"
            >
              Wood Pellets
            </Link>
            <Link
              to="/straw"
              className="col-sm-12 col-md-6 text-center footer-cta switch-sites wood"
            >
              Straw Pellets
            </Link>
          </div>
        </div>
      </div>
      <div className="fw footer sect-pad-top-sm sect-pad-bot-sm">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-3 logo">
              <img src={logoImages.strawWo} alt="" className="straw" />
							<img src={logoImages.woodWo} alt="" className="wood" />
            </div>
            <div className="col-sm-12 col-md-3 text-center text-md-left">
              <ul>
                {menuLinks.map(link => (
                  <li>
                    <Link key={link.title} to={`/${nav_prefix}${link.route}`}>
                      {link.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row footer-bottom">
            <div className="col-sm-12 col-md-6 text-center text-md-left">
              <p>© {new Date().getFullYear()} Straw Pellets Direct</p>
            </div>
            <div className="col-sm-12 col-md-6 text-center text-md-right">
              <p><a href="https://www.silverbackstudios.co.uk" target="_blank">Website by Silverback®</a></p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
