import React from "react"
import { Link } from "gatsby"

import { contactPageImages } from "../../constants/images.js"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ContactPage = () => (
  <Layout pageInfo={{ pageName: "contact" }}>
    <SEO title="Contact Us" />
    <div>
      <div className="fw banner-inner straw-banner-inner">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Contact</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="fw warm-grey-bg watermark-center sect-pad-top sect-pad-bot">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="fw padd-left-wrap contact-details">
                <h2 className="highlight">Send an enquiry</h2>
                <form
                  id="enquiry-form"
                  action="https://getform.io/f/a3be9708-34bf-4792-bbbc-ea14e026422d"
                  method="POST"
                >
                  <input
                    className="half-input form-control"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                  />
                  <input
                    className="half-input form-control"
                    type="text"
                    id="company"
                    name="company"
                    placeholder="Company"
                  />
                  <input
                    className="half-input form-control"
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                  />
                  <input
                    className="half-input form-control"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                  />
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    placeholder="Message"
                    defaultValue={""}
                  />
                  <input className="btn" type="submit" defaultValue="Submit" />
                </form>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 p-4">
                  <p>
                    Straw Pellets Direct, Tickfold Farm, Kingsfold, Horsham,
                    <br></br>
					West Sussex RH12 3SE
					<br></br>Telephone: 01306 627654 or 07974 739726
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 large-p">
              <img
                className="d-none d-md-block w-100"
                src={contactPageImages.afterForm}
                alt="contact photo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
