/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Location } from "@reach/router"
import Footer from "../components/footer"
import HeaderScripts from "../components/headerScripts"
import Navbar from "./navBar"

// if (typeof window !== `undefined`) {
//   console.log(
//     "%c.",
//     'font-size: 1px; line-height: 100px; width: 100px; padding: 30px 70px; background: url("https://silverbackstudios.co.uk/wp-content/themes/silverback-studios/images/mukunda-walks.gif") no-repeat; backgroud-repeat: no-repeat;'
//   )
// }
let the_location = ""
let the_class = ""

const Layout = ({ children, pageInfo, theme }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <HeaderScripts />
        <Location>
          {({ location }) => {
            if (location.pathname.includes("wood")) {
              the_location = "wood/"
              the_class = "wood"
            }
            if (location.pathname.includes("straw")) {
              the_location = "straw/"
              the_class = "straw"
            }
            return (
              <>
                <div
                  className={` page-container theme-container theme-${the_class}`}
                >
                  <Navbar pageInfo={pageInfo} the_location={the_location} />
                  <main>{children}</main>
                  <Footer the_class={the_class} the_location={the_location} />
                </div>
              </>
            )
          }}
        </Location>
      </>
    )}
  />
)

export default Layout
